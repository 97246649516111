import React from "react"

const FancyInput = ({ className, ...props }) => {
  return (
    <input
      className={`border border-gray-300 px-3 py-3 placeholder-blueGray-500 text-blueGray-600 bg-white rounded text-sm shadow
      hover:border-black transition duration-150 w-full ${className}`}
      {...props}
    />
  )
}

/**
 *
 * @param title Title of the card header: string
 * @param children Children elements to render: Array<JSX.Element>
 * @param padBottom Whether to pad the bottom, defaults to true: boolean
 */
export const FancyCardHeader = ({ title, children, padBottom = true }) => {
  return (
    <div className="text-blueGray-400 text-center mb-6 font-bold">
      <h6 className="text-blueGray-600 text-sm font-bold">{title}</h6>
      <hr className="mt-6 border-b-1 border-blueGray-400 mb-6" />
      {children}
      {padBottom ? <br /> : <></>}
    </div>
  )
}

export default FancyInput
