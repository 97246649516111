import React from "react"
import Layout from "../components/layout"
import Progress from "../components/progress"
import { redirectViaVerifyAPI, setLoginTokens } from "../utils"
import CenteredContainer from "../components/CenteredContainer"
import { FancyCardHeader } from "../components/FancyInput"

class AuthToken extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const cookieSetSuccefully = setLoginTokens()
    setTimeout(() => {
      redirectViaVerifyAPI()
    }, 500)
  }

  render() {
    return (
      <Layout bgimage dark stickyFooter>
        <CenteredContainer>
          <FancyCardHeader title="Logging In" />
          <div id="success">
            <Progress />
            <p id="redirectParagraph" className="subText">
              <span>Redirecting</span>
            </p>
          </div>
        </CenteredContainer>
      </Layout>
    )
  }
}

export default AuthToken
